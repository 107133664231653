import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Splash from "./pages/splash/splash";
import Home from "./pages/home/home";
import A from "./pages/A/A";
import B from "./pages/B/B";
import C from "./pages/C/C";
import D from "./pages/D/D";
import ChatPage from "./pages/ChatPage/ChatPage";
import NavBar from "./components/Navbar/Navbar";

function App() {
  return (
    <HashRouter basename="/">
      <NavBar />
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/home" element={<Home />} />
        <Route path="/experience" element={<A />} />
        <Route path="/education" element={<B />} />
        <Route path="/bookshelf" element={<C />} />
        <Route path="/photos" element={<D />} />
        <Route path="/chat" element={<ChatPage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
