import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Message.css";
import { motion } from "framer-motion";

export default function Message(props) {
  const { text, className, loading} = props;

  const LoadingContainer = {
    width: "10%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  };

  const ContainerVariants = {
    initial: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const DotVariants = {
    initial: {
      y: "-50%",
    },
    animate: {
      y: "50%",
    },
  };

  const DotTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  return (
    <Row>
      <Col className={className}>
        {loading ? (
          <motion.div
            style={LoadingContainer}
            variants={ContainerVariants}
            initial="initial"
            animate="animate"
          >
            <motion.span
              className="loading-dot"
              variants={DotVariants}
              transition={DotTransition}
            />
            <motion.span
              className="loading-dot"
              variants={DotVariants}
              transition={DotTransition}
            />
            <motion.span
              className="loading-dot"
              variants={DotVariants}
              transition={DotTransition}
            />
          </motion.div>
        ) : (<div>{text}</div>)}
      </Col>
    </Row>
  );
}
