import "../pages.css";
import "./D.css";
import { React, useEffect, useState, useRef } from "react";
import ParticleBackground from "../../components/ParticleBackground/ParticleBackground";
import PhotoGallery from "../../components/PhotoGallery/PhotoGallery";
import { motion } from "framer-motion";


const D = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const ref = useRef(null);

  const LoadingContainer = {
    width: "10rem",
    height: "5rem",
    display: "flex",
    justifyContent: "space-around",
  };

  const ContainerVariants = {
    initial: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const DotVariants = {
    initial: {
      y: "0%",
    },
    animate: {
      y: "100%",
    },
  };

  const DotTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  return (
    <>
      <ParticleBackground />
      {loading && (
        <div className="splash-screen">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <motion.div
              style={LoadingContainer}
              variants={ContainerVariants}
              initial="initial"
              animate="animate"
            >
              <motion.span
                className="dot"
                variants={DotVariants}
                transition={DotTransition}
              />
              <motion.span
                className="dot"
                variants={DotVariants}
                transition={DotTransition}
              />
              <motion.span
                className="dot"
                variants={DotVariants}
                transition={DotTransition}
              />
            </motion.div>
          </div>
        </div>
      )}
      <PhotoGallery />
    </>
  );
};

export default D;
