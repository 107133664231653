import "./BookForm.css";
import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { send } from "emailjs-com";

const EmailForm = () => {
  const [toSend, setToSend] = useState({
    title: "",
    author: "",
    book_notes: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      if (
        toSend.title === "" ||
        toSend.author === "" ||
        toSend.book_notes === ""
      ) {
        throw "form is missing information";
      }
    } catch (err) {
      console.log("FAILED...", err);
      handleShowFailure();
      return;
    }
    send(
      "service_z7e5dfh",
      "template_v361mp4",
      toSend,
      "6J6pYo9eUl7JLEKHs"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        handleShowSuccess();
      })
      .catch((err) => {
        console.log("FAILED...", err);
        handleShowFailure();
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const handleCloseFailure = () => setShowFailure(false);
  const handleShowFailure = () => setShowFailure(true);

  return (
    <>
      <Col
        id="formContainer"
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
      >
        <Container id="bookContainer">
          <Row id="formRow">
            <Col
              id="bookFormCol"
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            >
              <Container>
              <Row className="contactTitle">
                  <Col>
                    Explore my bookshelf and help it grow!
                  </Col>
                </Row>
                <Row className="contactTitleMessage">
                  <Col>
                    Share your favorite book recommendations.
                  </Col>
                </Row>
                <Form id="form" onSubmit={onSubmit}>
                  <Row>
                    <Col>
                      <Form.Group id="fromName" className="mb-3">
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Title"
                          className="bookForm"
                          value={toSend.from_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group id="fromName" className="mb-3">
                        <Form.Control
                          type="text"
                          name="author"
                          placeholder="Author"
                          className="bookForm"
                          value={toSend.from_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group id="emailMessage" className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="book_notes"
                          placeholder="Notes (Optional)"
                          className="bookForm"
                          value={toSend.message}
                          onChange={handleChange}
                          style={{"resize": "none"}}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="submitButton"
                        variant="outline-dark"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Col>
          </Row>
        </Container>
      </Col>
      <Modal show={showSuccess} onHide={handleCloseSuccess}>
        <Modal.Header closeButton>
          <Modal.Title>I just received your message.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thanks for reaching out. I'm looking forward to connecting with you
          soon!
        </Modal.Body>
      </Modal>
      <Modal show={showFailure} onHide={handleCloseFailure}>
        <Modal.Header closeButton>
          <Modal.Title>Something didn't go right...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please ensure that all information is filled out correctly and try
          again.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailForm;
