import React from "react";
import particlesConfig from "./particle-config";
import Particles from "react-tsparticles";
import "./ParticleBackground.css";

export default function ParticleBackground() {
  return (
    <div className="pcTest">
      <Particles params={particlesConfig}></Particles>
    </div>
  );
}
