import "../pages.css";
import "./C.css";
import * as React from "react";
import { Container, Row, Col, CloseButton } from "react-bootstrap";
import Magnifier from "react-magnifier";
import BookForm from "../../components/BookForm/BookForm";
import ParticleBackground from "../../components/ParticleBackground/ParticleBackground";


const C = () => {
  return (
    <>
        <ParticleBackground />
        <Container className="pageContentC">
          <Row className="contentRow">
            <Col
              id="bookColC"
              xs={{ span: 12, offset: 0 }}
              s={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <Magnifier
                className="img-fluid"
                src={require(`../../assets/photos/bookshelf.jpg`)}
                mgShape={"circle"}
                mgHeight={200}
                mgWidth={200}
                mgTouchOffsetX={0}
                mgTouchOffsetY={0}
                zoomFactor={1}
                alt="books"
              />
            </Col>
            <Col
              id="bookText"
              xs={{ span: 12, offset: 0 }}
              s={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
            >
              <BookForm />
            </Col>
          </Row>
        </Container>
    </>
  );
};

export default C;
