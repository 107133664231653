import "./AboutGrid.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Container, Row, Col } from "react-bootstrap";

const AboutGrid = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const boxVariants = {
    hidden: { opacity: 1, scale: 1 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0,
        delayChildren: 0.25,
        staggerChildren: 0.25,
      },
    },
  };
  const item = {
    visible: {
      x: "0vh",
    },
    hover: {
      borderRadius: '100%',
      transition: {
        duration: 0.3
      },
      color: "#66fcf1", // Change to your desired text color
    }
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      id="navGrid"
      className="container gridContainer"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={boxVariants}
    >
      <Row className="aboutRow">
        <Col
          className="contentRow"
          xs={{ span: 12, offset: 0 }}
          s={{ span: 12, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
          xl={{ span: 6, offset: 0 }}
        >
          <Link to={`/bookshelf`} style={{ opacity: 1 }}>
            <motion.div
              id="c"
              variants={item}
              whileTap={{
                scale: 0.99,
              }}
              whileHover="hover"
            >
              <Container>
                <Row className="contentRow">
                  <Col className="textCont">
                    <Container>
                      <Row className="gridTextTitle">
                        <Col>
                          Literary Logs
                        </Col>
                      </Row>
                      <Row className="gridText">
                        <Col>
                          Dive into some of the books I've read and suggest your favorites.
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col>
                    <motion.img
                      src={require(`../../assets/photos/library_new.png`)}
                      className="gridImg"
                      alt="reading"
                      variants={item}
                    />
                  </Col>
                </Row>
              </Container>
            </motion.div>
          </Link>
        </Col>
        <Col
          className="contentRow"
          xs={{ span: 12, offset: 0 }}
          s={{ span: 12, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
          xl={{ span: 6, offset: 0 }}
        >
          <Link to={`/photos`} style={{ opacity: 1 }}>
            <motion.div
              id="c"
              variants={item}
              whileTap={{
                scale: 0.99,
              }}
              whileHover="hover"
            >
              <Container>
                <Row className="contentRow">
                  <Col className="textCont">
                    <Container>
                      <Row className="gridTextTitle">
                        <Col>
                          Photography Logs
                        </Col>
                      </Row>
                      <Row className="gridText">
                        <Col>
                          Explore my creative side through my recent photography pursuits.
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col className="imageCont">
                    <motion.img
                      src={require(`../../assets/photos/projects_new.webp`)}
                      className="gridImg"
                      alt="reading"
                      variants={item}
                    />
                  </Col>
                </Row>
              </Container>
            </motion.div>
          </Link>
        </Col>
      </Row>
    </motion.div>
  );
};

export default AboutGrid;
