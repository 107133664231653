import "./Photos.css";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Photos = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const boxVariants = {
    hidden: { opacity: 1, scale: 1 }
  };
  const item = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="container"
      id="photoContainer"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={boxVariants}
    >
      <Row>
        <Col className="photoText">
          <Container>
            <Row>
              <Col className="photoTextTitleCol">
                <div>
                  Through My Lens: Moments in Focus
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="photoTextItalCol">
                <div>
                  There is nothing worse than a sharp image of a fuzzy concept.
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="photoTextCol">
                  This wisdom from Ansel Adams not only applies to photography but also finds resonance in the realms of product development. 
                  Both fields are driven by the pursuit of clarity and precision, where the importance of a well-defined 
                  concepts cannot be overstated.
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          xs={{ span: 12, offset: 0 }}
          s={{ span: 12, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
          xl={{ span: 6, offset: 0 }}
          className="photo"
        >
          <motion.img
            variants={item}
            className="img-fluid photo"
            src={require("../../assets/photos/9.jpg")}
            alt="views"
          />
        </Col>
      </Row>
      <Row id="photoRow">
      <Col
          xs={{ span: 6, offset: 0 }}
          s={{ span: 6, offset: 0 }}
          md={{ span: 4, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          className="photo"
        >
          <motion.img
            variants={item}
            className="img-fluid photo"
            src={require("../../assets/photos/7.jpg")}
            alt="views"
          />
        </Col>
        <Col
          xs={{ span: 6, offset: 0 }}
          s={{ span: 6, offset: 0 }}
          md={{ span: 4, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          className="photo"
        >
          <motion.img
            variants={item}
            className="img-fluid photo"
            src={require("../../assets/photos/1.jpg")}
            alt="views"
          />
        </Col>
        <Col
          xs={{ span: 12, offset: 0 }}
          s={{ span: 12, offset: 0 }}
          md={{ span: 4, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          className="photo"
        >
          <motion.img
            variants={item}
            className="img-fluid photo"
            src={require("../../assets/photos/2.jpg")}
            alt="views"
          />
        </Col>
      </Row>
      <Row className="d-none d-md-flex" id="photoRow">
      <Col
          xs={{ span: 6, offset: 0 }}
          s={{ span: 6, offset: 0 }}
          md={{ span: 4, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          className="photo"
        >
          <motion.img
            variants={item}
            className="img-fluid photo"
            src={require("../../assets/photos/6.jpg")}
            alt="views"
          />
        </Col>
        <Col
          xs={{ span: 6, offset: 0 }}
          s={{ span: 6, offset: 0 }}
          md={{ span: 4, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          className="photo"
        >
          <motion.img
            variants={item}
            className="img-fluid photo"
            src={require("../../assets/photos/water.JPG")}
            alt="views"
          />
        </Col>
        <Col
          xs={{ span: 6, offset: 0 }}
          s={{ span: 6, offset: 0 }}
          md={{ span: 4, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          xl={{ span: 4, offset: 0 }}
          className="photo"
        >
          <motion.img
            variants={item}
            className="img-fluid photo"
            src={require("../../assets/photos/duck.JPG")}
            alt="views"
          />
        </Col>
      </Row>
    </motion.div>
  );
};

export default Photos;
