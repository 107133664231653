import "../pages.css";
import "./ChatPage.css";
import * as React from "react";
import Chat from "../../components/Chat/Chat";
import ParticleBackground from "../../components/ParticleBackground/ParticleBackground";


const ChatPage = () => {
  return (
    <>
        <ParticleBackground />
        <Chat/>
    </>
  );
};

export default ChatPage;

