import "./EmailForm.css";
import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { send } from "emailjs-com";

const EmailForm = () => {
  const [toSend, setToSend] = useState({
    from_name: "",
    phone_number: "",
    message: "",
    reply_to: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      if (
        toSend.from_name === "" ||
        toSend.subject === "" ||
        toSend.message === "" ||
        toSend.reply_to === ""
      ) {
        throw "form is missing information";
      }
    } catch (err) {
      console.log("FAILED...", err);
      handleShowFailure();
      return;
    }
    send(
      "service_z7e5dfh",
      "template_v361mp4",
      toSend,
      "6J6pYo9eUl7JLEKHs"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        handleShowSuccess();
      })
      .catch((err) => {
        console.log("FAILED...", err);
        handleShowFailure();
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const handleCloseFailure = () => setShowFailure(false);
  const handleShowFailure = () => setShowFailure(true);

  return (
    <>
      <Col
        id="formContainer"
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
      >
        <Container id="emailContainer">
          <Row id="formRow">
            <Col
              id="emailCol"
              className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12"
            >
              <Row>
                <Container>
                  <Row id="contactTitle">
                    <Col>
                      Let's build together.
                    </Col>
                  </Row>
                  <Row id="contactTitleMessage">
                    <Col>
                      I'd love to hear from you, contact me using the form below and I'll get back to you right away.
                    </Col>
                  </Row>
                </Container>
              </Row>
              <Form id="form" onSubmit={onSubmit}>
                <Row>
                  <Col>
                    <Form.Group id="fromName" className="mb-3">
                      <Form.Control
                        type="text"
                        name="from_name"
                        placeholder="Name"
                        className="emailForm"
                        value={toSend.from_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group id="fromEmail" className="mb-3">
                      <Form.Control
                        type="text"
                        name="reply_to"
                        placeholder="Email Address"
                        className="emailForm"
                        value={toSend.subject}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group id="fromPhoneNumber" className="mb-3">
                      <Form.Control
                        type="text"
                        name="phone_number"
                        placeholder="Phone Number (optional)"
                        className="emailForm"
                        value={toSend.phone_number}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group id="emailMessage" className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="message"
                        placeholder="Message"
                        className="emailForm"
                        value={toSend.message}
                        onChange={handleChange}
                        style={{"resize": "none"}}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      className="submitButton"
                      variant="outline-dark"
                    >
                      Send Message
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col className="col-xl-5 col-lg-5 col-md-0 col-sm-0 col-0">
              <img
                id="picCol"
                alt="saaPicture"
                className="img-fluid"
                src={require("../../assets/photos/portrait.jpg")}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"></Col>
            <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-0 col-0"></Col>
          </Row>
        </Container>
      </Col>
      <Modal show={showSuccess} onHide={handleCloseSuccess}>
        <Modal.Header closeButton>
          <Modal.Title>I just received your message.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thanks for reaching out. I'm looking forward to connecting with you
          soon!
        </Modal.Body>
      </Modal>
      <Modal show={showFailure} onHide={handleCloseFailure}>
        <Modal.Header closeButton>
          <Modal.Title>Something didn't go right...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please ensure that all information is filled out correctly and try
          again.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailForm;
