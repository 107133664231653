import "../pages.css";
import "./splash.css";
import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Splash = () => {
  const ref = useRef(null);

  const LoadingContainer = {
    width: "10rem",
    height: "5rem",
    display: "flex",
    justifyContent: "space-around",
  };

  const ContainerVariants = {
    initial: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const DotVariants = {
    initial: {
      y: "0%",
    },
    animate: {
      y: "100%",
    },
  };

  const DotTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  useEffect(() => {
    setTimeout(() => {
      ref.current.click();
    }, 1500);
  }, []);

  return (
    <Container className="splashScreen">
      <Row className="contentRow">
        <Col className="contentRow">
          <Link to={`/home`} ref={ref}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <motion.div
                style={LoadingContainer}
                variants={ContainerVariants}
                initial="initial"
                animate="animate"
              >
                <motion.span
                  className="dot"
                  variants={DotVariants}
                  transition={DotTransition}
                />
                <motion.span
                  className="dot"
                  variants={DotVariants}
                  transition={DotTransition}
                />
                <motion.span
                  className="dot"
                  variants={DotVariants}
                  transition={DotTransition}
                />
              </motion.div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Splash;
