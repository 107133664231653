import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./Chat.css";
import Message from "./Message";  
  

function Chat() {
  const [messages, setMessages] = useState([
    {
      role: 'assistant',
      content: "Hi there! You can ask me anything and I'll be happy to answer from Stephen's perspective. While you can ask me anything, "
      + "I may not be able to answer. However, I am constantly learning and improving my knowledge and capabilities.",
      loading: false
    }
  ]);

  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const newMessage = {
        role: 'user',
        content: inputValue,
        loading: false
      };

      const loadingMessage = {
        role: 'assistant',
        content: '',
        loading: true
      };

      const requestMessage = [...messages, newMessage]
      const updatedMessages = [...messages, newMessage, loadingMessage]

      setMessages(updatedMessages);
      setInputValue('');
    
      const response = await fetch('https://genai-sandbox-api-xkulp4e4kq-uc.a.run.app/v1/sandbox/character/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          domain: "character.stephen",
          messages: requestMessage 
        }),
      });
  
      if (!response.ok) {
        throw new Error('API request failed');
      }
  
      const responseData = await response.json();

      updatedMessages[updatedMessages.length - 1] = {
        role: 'assistant',
        content: responseData['completion'],
        loading: false
      }
  
      setMessages([...updatedMessages]);
    } catch (error) {
      // Handle API call errors here
      console.error('API call error:', error);
    }
  };


  const chatContainerRef = useRef(null);
  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);


  return (
    <>
      <Container className="OverlapOneChat">
        <Row>
          <Col className="tellMore">
            <Container className="chatContainer">
              <Row className="chatMessages">
                <Col className="chatContainer" ref={chatContainerRef}>
                  <Container>
                    {messages.map((message, index) => (
                      <Message key={index} className={message.role} text={message.content} loading={message.loading}/>
                    ))}
                  </Container>
                </Col>
              </Row>
              <Row className="promptInput">
                <Col>
                  <Container className="promptInputCont">
                    <Form className="promptInputRow"
                      onSubmit={handleSubmit}
                    >
                      <Row className="promptInputRow">
                        <Col className="promptInputCol">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="from_name"
                              className="promptInputForm"
                              value={inputValue}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="promptSubmitCol col-auto">
                          <Button
                            type="submit"
                            id="promptSubmitBtn"
                            variant="outline-none"
                          >
                            <img
                              src={require(`../../assets/photos/send.png`)}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Chat;
