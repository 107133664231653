import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Stage1.css";
import HelloWorld from "../HelloWorld/HelloWorld";
import AboutGrid from "../AboutGrid/AboutGrid";
import Photos from "../Photos/Photos";
import ParticleBackground from "../ParticleBackground/ParticleBackground";
import EmailForm from "../EmailForm/EmailForm";

function Stage1() {
  return (
    <>
      <ParticleBackground />
      <Container className="siteContent" id="Home">
        <Row className="firstScreen">
          <Col className="test">
            <HelloWorld />
          </Col>
        </Row>
        <Row id="Photos">
          <Photos />
        </Row>
        <Row id="About">
          <AboutGrid />
        </Row>
        <Row id="Contact">
          <EmailForm />
        </Row>
      </Container>
    </>
  );
}

export default Stage1;
