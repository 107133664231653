import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import "./HelloWorld.css";

function Summary() {
  return (
    <Col className="summary">
      <Typewriter
        options={{
          loop: false,
          delay: 60,
          speed: 40,
        }}
        onInit={(typewriter) => {
          typewriter
            .pauseFor(1000)
            .typeString("Hello world!")
            .pauseFor(500)
            .deleteChars(20)
            .pauseFor(100)
            .typeString("I am a <strong>software engineer.</strong>")
            .pauseFor(500)
            .deleteChars(19)
            .pauseFor(100)
            .typeString("n <strong>enthusiastic learner.</strong>")
            .pauseFor(500)
            .deleteChars(23)
            .pauseFor(100)
            .typeString(" <strong>charismatic leader.</strong>")
            .pauseFor(500)
            .deleteChars(21)
            .pauseFor(100)
            .typeString("<strong>Stephen Allen.</strong>")
            .start();
        }}
      />
    </Col>
  );
}

function HelloWorld() {
  function getDiv() {
    return <Summary />;
  }

  return (
    <>
      <Container className="OverlapOne">
        <Row>
          <Col>{getDiv()}</Col>
        </Row>
        <Row>
          <Col className="tellButtonCol">
            <Link to={`/chat`} style={{ opacity: 1 }}>
              <Button
                className="tellButton"
                variant="outline-light"
              >
                Ask Me Anything
                  <span style={{ 
                    marginLeft: '5px', 
                    fontSize: 'small', 
                    fontWeight: 'bold', 
                    border: '1px solid', 
                    padding: '3px', 
                    borderRadius: '10px', 
                    color: '#45a29e' 
                  }}>
                    Beta
                  </span>
              </Button>
            </Link>  
          </Col>
        </Row>
      </Container>
      <HashLink className="bottomDiv" to={`/home/#Photos`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          className="bi bi-chevron-compact-down"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
          />
        </svg>
      </HashLink>
    </>
  );
}

export default HelloWorld;
