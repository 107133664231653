import "../pages.css";
import "./B.css";
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, CloseButton, Card } from "react-bootstrap";

const B = () => {
  const navigate = useNavigate();
  const [showUNC, setShowUNC] = useState(false);
  const [showNCSSM, setShowNCSSM] = useState(false);
  const [showLU, setShowLU] = useState(false);

  const education = {
    hidden: { y: "-100vh" },
    visible: {
      y: "1vh",
      transition: {
        type: "spring",
        delay: 0.7,
        stiffness: 85,
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <motion.div
        variants={education}
        initial="hidden"
        animate="visible"
        className="container newContainer"
      >
        <CloseButton id="close" onClick={() => navigate(-1)} />
        <Row className="contentRow">
          <Col id="front">
            <Container className="accordions">
              <Row id="topRow">
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                >
                  <motion.div
                    className="card unc"
                    variants={item}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{
                      scale: 0.99,
                    }}
                    onClick={() => setShowUNC(!showUNC)}
                  >
                    <motion.header initial={false}>
                      <Container>
                        <Row>
                          <Col
                            xs={{ span: 10, offset: 1 }}
                            s={{ span: 10, offset: 1 }}
                            md={{ span: 8, offset: 2 }}
                            lg={{ span: 8, offset: 2 }}
                            xl={{ span: 8, offset: 2 }}
                          >
                            <img
                              id="uncImg"
                              src={require(`./unc.png`)}
                              className="img-fluid"
                              alt="unc"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </motion.header>
                    <AnimatePresence initial={false}>
                      {showUNC && (
                        <motion.section
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 0.8,
                            ease: [0.04, 0.62, 0.23, 0.98],
                          }}
                        >
                          <Card.Body id="uncBody">
                            During my time at Carolina, I was able to explore my
                            passions and discover what I love to do. Surrounded
                            by other Tar Heels, I pursued my interest in
                            computer science and confirmed my belief that
                            technology can serve as a tool to solve our world’s
                            greatest problems.
                          </Card.Body>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </Col>
              </Row>
              <Row id="bottomRow">
                <Col
                  className="ncssm"
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                >
                  <motion.div
                    className="card ncssm"
                    variants={item}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{
                      scale: 0.99,
                    }}
                    onClick={() => setShowNCSSM(!showNCSSM)}
                  >
                    <motion.header initial={false}>
                      <Container>
                        <Row>
                          <Col
                            xs={{ span: 10, offset: 1 }}
                            s={{ span: 10, offset: 1 }}
                            md={{ span: 6, offset: 3 }}
                            lg={{ span: 6, offset: 3 }}
                            xl={{ span: 6, offset: 3 }}
                          >
                            <img
                              id="ncssmImg"
                              src={require(`./ncssm.png`)}
                              className="img-fluid"
                              alt="ncssm"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </motion.header>
                    <AnimatePresence initial={false}>
                      {showNCSSM && (
                        <motion.section
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 0.8,
                            ease: [0.04, 0.62, 0.23, 0.98],
                          }}
                        >
                          <Card.Body className="otherBody">
                            I would not be who I am today without my two years
                            at NCSSM. I stepped out of my comfort zone and
                            accepted the greater challenge, an opportunity which
                            ultimately gifted me with my love for learning.
                          </Card.Body>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </Col>
                <Col
                  className="lu"
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                >
                  <motion.div
                    className="card lu"
                    variants={item}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{
                      scale: 0.99,
                    }}
                    onClick={() => setShowLU(!showLU)}
                  >
                    <motion.header initial={false}>
                      <Container>
                        <Row>
                          <Col
                            xs={{ span: 10, offset: 1 }}
                            s={{ span: 10, offset: 1 }}
                            md={{ span: 6, offset: 3 }}
                            lg={{ span: 6, offset: 3 }}
                            xl={{ span: 6, offset: 3 }}
                          >
                            <img
                              id="luImg"
                              src={require(`./lu.jpg`)}
                              className="img-fluid"
                              alt="lu"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </motion.header>
                    <AnimatePresence initial={false}>
                      {showLU && (
                        <motion.section
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 0.8,
                            ease: [0.04, 0.62, 0.23, 0.98],
                          }}
                        >
                          <Card.Body className="otherBody">
                            My semester abroad in the United Kingdom allowed me
                            to search for new ideas and opportunities to better
                            understand the world around me. It was there that I
                            found my love of trains and afternoon tea.
                          </Card.Body>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </motion.div>
      <Container fluid className="gridContainer">
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
          >
            <motion.div id="a"></motion.div>
          </Col>
        </Row>
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 11, offset: -1 }}
            lg={{ span: 11, offset: -1 }}
            xl={{ span: 11, offset: -1 }}
          >
            <motion.div
              id="b"
              animate={{ scale: 15 }}
              transition={{ duration: 0.7 }}
            ></motion.div>
          </Col>
        </Row>
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 10, offset: -2 }}
            lg={{ span: 10, offset: -2 }}
            xl={{ span: 10, offset: -2 }}
          >
            <motion.div id="d"></motion.div>
          </Col>
        </Row>
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 9, offset: -3 }}
            lg={{ span: 9, offset: -3 }}
            xl={{ span: 9, offset: -3 }}
          >
            <motion.div id="c"></motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default B;
