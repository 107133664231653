import "../pages.css";
import "./A.css";
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, CloseButton, Card } from "react-bootstrap";

const A = () => {
  const navigate = useNavigate();
  const [showGEA, setShowGEA] = useState(false);
  const [showPFE, setShowPFE] = useState(false);
  const [showHH, setShowHH] = useState(false);

  const experiences = {
    hidden: { y: "-100vh" },
    visible: {
      y: "1vh",
      transition: {
        type: "spring",
        delay: 0.7,
        stiffness: 85,
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <motion.div
        variants={experiences}
        initial="hidden"
        animate="visible"
        className="container newContainer"
      >
        <CloseButton id="close" onClick={() => navigate(-1)} />
        <Row className="contentRow">
          <Col id="front">
            <Container className="accordions">
              <Row className="contentRow">
                <motion.div className="col experienceCol col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <motion.div
                    className="card experience"
                    variants={item}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{
                      scale: 0.99,
                    }}
                    onClick={() => setShowGEA(!showGEA)}
                  >
                    <motion.header initial={false}>
                      <Container>
                        <Row>
                          <Col
                            xs={{ span: 10, offset: 1 }}
                            s={{ span: 10, offset: 1 }}
                            md={{ span: 10, offset: 1 }}
                            lg={{ span: 10, offset: 1 }}
                            xl={{ span: 10, offset: 1 }}
                          >
                            <img
                              src={require(`./assets/gea.jpg`)}
                              className="img-fluid"
                              alt="gea"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </motion.header>
                    <AnimatePresence initial={false}>
                      {showGEA && (
                        <motion.section
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 1,
                          }}
                        >
                          <Card.Body className="experienceBody">
                            <h6>
                              <strong>
                                Digital Leadership Development Program Associate
                              </strong>
                            </h6>
                            <p>
                              Serve as an agile developer on the SmartHQ Home &
                              Features team assisting with new feature
                              implementation across product lines, in addition
                              to enabling insights for the SmartHQ platform.
                            </p>
                            <p>
                              Provide ongoing support of existing applications
                              while following team protocols for change
                              management, automated builds and deployment, and
                              AWS cloud architecture.
                            </p>
                            <p>
                              Leveraged Oracle Analytics Cloud to successfully
                              deliver enterprise-scale collaborative analytics
                              projects which ultimately resulted in annual cost
                              savings of $238,000 for the business.
                            </p>
                          </Card.Body>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </motion.div>
                <motion.div className="col experienceCol col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <motion.div
                    className="card experience"
                    variants={item}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{
                      scale: 0.99,
                    }}
                    onClick={() => setShowPFE(!showPFE)}
                  >
                    <motion.header initial={false}>
                      <Container className="p-2">
                        <Row>
                          <Col
                            xs={{ span: 10, offset: 1 }}
                            s={{ span: 10, offset: 1 }}
                            md={{ span: 10, offset: 1 }}
                            lg={{ span: 10, offset: 1 }}
                            xl={{ span: 10, offset: 1 }}
                          >
                            <img
                              src={require(`./assets/pfizer.jpg`)}
                              className="img-fluid"
                              alt="ncssm"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </motion.header>
                    <AnimatePresence initial={false}>
                      {showPFE && (
                        <motion.section
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 1,
                          }}
                        >
                          <Card.Body className="experienceBody">
                            <h6>
                              <strong>Digital Intern</strong>
                            </h6>
                            <p>
                              Worked as an agile developer on the Raylumis
                              Digital Companion hybrid application focused
                              primarily on the addition of user-centered data
                              insights and visualization features.
                            </p>
                            <p>
                              Implemented the first comprehensive end-to-end
                              automated testing suite for the Raylumis Digital
                              Companion project with Selenium WebDriver, Pytest,
                              and Cucumber.
                            </p>
                            <p>
                              Contributed to a development tool used by the This
                              Is Living With Cancer team maintaining code
                              versions through Git.
                            </p>
                          </Card.Body>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </motion.div>
                <motion.div className="col experienceCol col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <motion.div
                    className="card experience"
                    variants={item}
                    whileHover={{ scale: 1.01 }}
                    whileTap={{
                      scale: 0.99,
                    }}
                    onClick={() => setShowHH(!showHH)}
                  >
                    <motion.header initial={false}>
                      <Container>
                        <Row>
                          <Col
                            xs={{ span: 10, offset: 1 }}
                            s={{ span: 10, offset: 1 }}
                            md={{ span: 10, offset: 1 }}
                            lg={{ span: 10, offset: 1 }}
                            xl={{ span: 10, offset: 1 }}
                          >
                            <img
                              src={require(`./assets/hashedHealth.jpg`)}
                              className="img-fluid"
                              alt="lu"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </motion.header>
                    <AnimatePresence initial={false}>
                      {showHH && (
                        <motion.section
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            duration: 1,
                          }}
                        >
                          <Card.Body className="experienceBody">
                            <h6>
                              <strong>Summer Intern</strong>
                            </h6>
                            <p>
                              Conducted UX planning and wireframing on key
                              initiatives associated with the Professional
                              Credentials Exchange engineering team.
                            </p>
                            <p>
                              Researched and developed an informative market
                              survey and “Healthcare Blockchain Consortia
                              Initiatives” newsletter series in order to
                              increase support for blockchain solutions in
                              healthcare.
                            </p>
                          </Card.Body>
                        </motion.section>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </motion.div>
              </Row>
            </Container>
          </Col>
        </Row>
      </motion.div>
      <Container fluid className="gridContainer">
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
          >
            <motion.div
              id="a"
              animate={{ scale: 15 }}
              transition={{ duration: 0.7 }}
            ></motion.div>
          </Col>
        </Row>
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 11, offset: -1 }}
            lg={{ span: 11, offset: -1 }}
            xl={{ span: 11, offset: -1 }}
          >
            <motion.div id="b"></motion.div>
          </Col>
        </Row>
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 10, offset: -2 }}
            lg={{ span: 10, offset: -2 }}
            xl={{ span: 10, offset: -2 }}
          >
            <motion.div id="d"></motion.div>
          </Col>
        </Row>
        <Row className="aboutRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 9, offset: -3 }}
            lg={{ span: 9, offset: -3 }}
            xl={{ span: 9, offset: -3 }}
          >
            <motion.div id="c"></motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default A;
