import "./PhotoGallery.css";
import { React } from "react";
import { Container, Row, Col } from "react-bootstrap";

const PhotoGallery = () => {
  return (
    <>
      <Container className="galleryContainer">
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            className="photoGallery"
          >
            <Container>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/minster_small.JPG")}
                    alt="views"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/flower_small.png")}
                    alt="views"
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/helvellyn_views.JPG")}
              alt="views"
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/spiral_staircase.JPG")}
              alt="views"
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/accordion_dog.JPG")}
              alt="views"
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            className="photoGallery"
          >
            <Container>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/duck_flying.JPG")}
                    alt="views"
                  />
                </Col>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/munich_nights.JPG")}
                    alt="views"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/over_prague.JPG")}
                    alt="views"
                  />
                </Col>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/alpen.JPG")}
                    alt="views"
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row id="photoRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            className="photoGallery"
          >
            <Container>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/loom.JPG")}
                    alt="views"
                  />
                </Col>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/patterns.JPG")}
                    alt="views"
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/trinity.JPG")}
              alt="views"
            />
          </Col>
        </Row>
        <Row id="photoRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/4.jpg")}
              alt="views"
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/0.jpg")}
              alt="views"
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/tempered.JPG")}
              alt="views"
            />
          </Col>
        </Row>
        <Row id="photoRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/horse.JPG")}
              alt="views"
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            className="photoGallery"
          >
            <Container>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/freedom_wall.JPG")}
                    alt="views"
                  />
                </Col>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/5.jpg")}
                    alt="views"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/german_engineering.JPG")}
                    alt="views"
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/me_cliffs.JPG")}
              alt="views"
            />
          </Col>
        </Row>
        <Row id="photoRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/reflections.JPG")}
              alt="views"
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/inception.JPG")}
              alt="views"
            />
          </Col>
        </Row>
        <Row id="photoRow">
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 8, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            className="photoGallery"
          >
            <Container>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/dublin_castle.JPG")}
                    alt="views"
                  />
                </Col>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/prague_rolls.JPG")}
                    alt="views"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/rooster.JPG")}
                    alt="views"
                  />
                </Col>
                <Col
                  xs={{ span: 12, offset: 0 }}
                  s={{ span: 12, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                  xl={{ span: 6, offset: 0 }}
                  className="photoGallery"
                >
                  <img
                    className="img-fluid photoGallery"
                    src={require("../../assets/photos/overboard.JPG")}
                    alt="views"
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            s={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xl={{ span: 4, offset: 0 }}
            className="photoGallery"
          >
            <img
              className="img-fluid photoGallery"
              src={require("../../assets/photos/allianz.JPG")}
              alt="views"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PhotoGallery;
